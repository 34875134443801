import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';

interface UserState {
    email: string;
    username: string;
    code: string;
}

// Define the initial state using that type
const initialState: UserState = {
    email: '',
    username: '',
    code: '',
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveUser: (state, action: PayloadAction<UserState>) => {
            state.email = action.payload.email;
            state.code = action.payload.code;
            state.username = action.payload.username;
        },
    },
});

// Action creators are generated for each case reducer function
export const { saveUser } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState) => state.user;

export default userSlice.reducer;
