import { Collection } from '@prisma/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export type NewLinkprops = {
    url: string;
    title?: string;
    image: string | null;
    description?: string;
    source?: string;
    content?: string;
    tags: string[];
    category?: string;
    collection?: Pick<
        Collection,
        'sticker' | 'collectionName' | 'collection_id'
    >;
};
// Define the initial state using that type
export const initialState: NewLinkprops = {
    url: '',
    title: '',
    image: '',
    description: '',
    category: 'Custom',
    source: '',
    tags: [],
};

export const linkDataSlice = createSlice({
    name: 'linkData',
    initialState,
    reducers: {
        setLinkTitle: (
            state,
            action: PayloadAction<Pick<NewLinkprops, 'title'>>
        ) => {
            state.title = action.payload.title;
        },
        setLinkDescription: (
            state,
            action: PayloadAction<Pick<NewLinkprops, 'description'>>
        ) => {
            state.description = action.payload.description;
        },
        setContent: (
            state,
            action: PayloadAction<Pick<NewLinkprops, 'content'>>
        ) => {
            state.content = action.payload.content;
        },
        setCategory: (
            state,
            action: PayloadAction<Pick<NewLinkprops, 'category'>>
        ) => {
            state.category = action.payload.category;
        },
        setTags: (state, action: PayloadAction<Pick<NewLinkprops, 'tags'>>) => {
            state.tags = action.payload.tags;
        },
        selectCollection: (
            state,
            action: PayloadAction<Pick<NewLinkprops, 'collection'>>
        ) => {
            state.collection = action.payload.collection;
        },

        setLinkData: (state, action: PayloadAction<NewLinkprops>) => {
            state.url = action.payload.url;
            state.title = action.payload.title;
            state.image = action.payload.image;
            state.description = action.payload.description;
            state.source = action.payload.source;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setLinkData,
    setLinkTitle,
    setLinkDescription,
    setContent,
    setTags,
    setCategory,
    selectCollection,
} = linkDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState) => state.user;

export default linkDataSlice.reducer;
