import { Collection } from '@prisma/client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAPIURL } from '@/utils/getAPIURL';

export const deleteCollectionById = createAsyncThunk(
    'users/fetchByIdStatus',
    async ({
        collection_id,
    }: Partial<Collection>): Promise<DeleteCollection> => {
        try {
            if (!collection_id) {
                return {
                    status: false,
                    loading: 'idle',
                    currentRequestId: undefined,
                };
            }
            const request = await fetch(
                `${getAPIURL()}/api/collection/deleteCollection?collection_id=${collection_id}`,
                {
                    method: 'GET',
                }
            );
            const response = await request.json();
            if (response.status) {
                return {
                    status: response.status,
                    loading: 'idle',
                    currentRequestId: undefined,
                };
            } else {
                return {
                    status: response.status,
                    loading: 'idle',
                    currentRequestId: undefined,
                };
            }
        } catch (error) {
            return {
                status: false,
                loading: 'idle',
                currentRequestId: undefined,
            };
        }
    }
);

type DeleteCollection = {
    status: boolean;
    currentRequestId: string | undefined;
    loading: 'idle' | 'pending';
};

const initialState: DeleteCollection = {
    status: false,
    currentRequestId: undefined,
    loading: 'idle',
};
const deleteCollectionSlice = createSlice({
    name: 'deleteCollection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteCollectionById.pending, (state, action) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending';
                    state.currentRequestId = action.meta.requestId;
                }
            })
            .addCase(deleteCollectionById.fulfilled, (state, action) => {
                const { requestId } = action.meta;
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                    state.status = true;
                }
            })
            .addCase(deleteCollectionById.rejected, (state, action) => {
                const { requestId } = action.meta;
                if (
                    state.loading === 'pending' &&
                    state.currentRequestId === requestId
                ) {
                    state.loading = 'idle';
                    state.currentRequestId = undefined;
                }
            });
    },
});

export default deleteCollectionSlice.reducer;
