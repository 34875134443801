import ProgressBar from '@badrap/bar-of-progress';
import { AppProps } from 'next/app';
import Router from 'next/router';
import Script from 'next/script';
import { Provider } from 'react-redux';

import '@/styles/globals.css';

// !STARTERCONF This is for demo purposes, remove @/styles/colors.css import immediately
import Layout from '@/components/layout/Layout';

import store from '@/redux/store/store';

const progress = new ProgressBar({
    size: 2,
    color: '#FFFF',
    className: 'bar-of-progress',
    delay: 100,
});

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', () => {
    progress.finish();
    window.scrollTo(0, 0);
});
Router.events.on('routeChangeError', progress.finish);

function MyApp({ Component, pageProps }: AppProps) {
    return (
        <>
            <Script
                strategy='afterInteractive'
                src={`https://www.clarity.ms/tag/`}
            />
            <Script
                id='gtag-init'
                strategy='afterInteractive'
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(c,l,a,r,i,t,y){
                        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "b7s33dyvvq");
          `,
                }}
            />
            <Script
                strategy='afterInteractive'
                src={`https://www.googletagmanager.com/gtag/js?id=G-RYSTKQTBHJ`}
            />
            <Script
                id='gtag-init'
                strategy='afterInteractive'
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-RYSTKQTBHJ', {
              page_path: window.location.pathname,
            });
          `,
                }}
            />
            <Provider store={store}>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
            </Provider>
        </>
    );
}

export default MyApp;
