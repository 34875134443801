import { combineReducers } from '@reduxjs/toolkit';

import createCollectionSlice from './collection/addnewCollection';
import collectionSlice from './collection/collectionSlice';
import collectionsSlice from './collection/collectionsSlice';
import deleteCollection from './collection/deleteCollection';
import updateCollectionSlice from './collection/updateCollection';
import linkDataSlice from './Link/link';
import tagDataSlice from './Link/tag';
import userTagsSlice from './tags/tags';
import counterreducer from './userSlice';

export const rootReducer = combineReducers({
    user: counterreducer,
    collection: collectionSlice,
    collections: collectionsSlice,
    newCollection: createCollectionSlice,
    deleteCollection: deleteCollection,
    updateCollection: updateCollectionSlice,
    newLink: linkDataSlice,
    tagData: tagDataSlice,
    userTags: userTagsSlice,
});
export type RootState = ReturnType<typeof rootReducer>;
