import { tags } from '@prisma/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export type TagProps = {
    updatedTags: tags[];
};
// Define the initial state using that type
export const initialState: TagProps = {
    updatedTags: [],
};

export const tagDataSlice = createSlice({
    name: 'linkData',
    initialState,
    reducers: {
        setTagData: (state, action: PayloadAction<TagProps>) => {
            state.updatedTags = action.payload.updatedTags;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setTagData } = tagDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState) => state.user;

export default tagDataSlice.reducer;
