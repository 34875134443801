import { Collection } from '@prisma/client';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAPIURL } from '@/utils/getAPIURL';

export type CollectionProps = Pick<
    Collection,
    | 'collection_id'
    | 'sticker'
    | 'collectionName'
    | 'themeColor'
    | 'description'
>;
export const updateCollection = createAsyncThunk(
    'updateCollection',
    async ({
        sticker,
        collectionName,
        themeColor,
        description,
        collection_id,
    }: CollectionProps) => {
        try {
            const request = await fetch(
                `${getAPIURL()}/api/collection/updateCollection`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        collection_id: collection_id,
                        sticker: sticker,
                        collectionName: collectionName,
                        themeColor: themeColor,
                        description: description,
                    }),
                }
            );

            const response = await request.json();
            if (response.status) {
                return {
                    status: true,
                    data: response.data,
                };
            } else {
                return {
                    status: true,
                    data: null,
                };
            }
        } catch (error) {
            return {
                status: true,
                data: null,
            };
        }
    }
);

type NewCollection = {
    loading: 'idle' | 'pending' | 'finished';
    status: boolean;
    data: Collection | null;
};
// Define the initial state using that type
const initialState: NewCollection = {
    loading: 'idle',
    status: false,
    data: null,
};

const updateCollectionSlice = createSlice({
    name: 'updateCollection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateCollection.pending, (state) => {
                if (state.loading === 'idle' || state.loading === 'finished') {
                    state.loading = 'pending';
                    state.status = false;
                }
            })
            .addCase(updateCollection.fulfilled, (state, action) => {
                if (state.loading === 'pending') {
                    state.loading = 'finished';
                    state.status = true;
                    state.data = action.payload.data;
                }
            })
            .addCase(updateCollection.rejected, (state) => {
                if (state.loading === 'pending') {
                    state.loading = 'idle';
                    state.status = false;
                }
            });
    },
});

export default updateCollectionSlice.reducer;
