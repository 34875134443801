import { tags } from '@prisma/client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAPIURL } from '@/utils/getAPIURL';

export const fetchUserTags = createAsyncThunk(
    'fetchUserTags',
    async (userId: string) => {
        try {
            const request = await fetch(
                `${getAPIURL()}/api/tag?name=usertags&userId=${userId}`,
                {
                    method: 'GET',
                }
            );
            const response: Pick<UserTags, 'usertags'> = await request.json();
            return response.usertags;
        } catch (error) {
            return [];
        }
    }
);

type UserTags = {
    usertags: tags[];
    loading: boolean;
};
// Define the initial state using that type
const initialState: UserTags = {
    usertags: [],
    loading: false,
};

const userTagsSlice = createSlice({
    name: 'usertags',
    initialState,
    reducers: {
        setUserTags: (state, action: PayloadAction<UserTags>) => {
            state.usertags = action.payload.usertags;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserTags.pending, (state) => {
                if (state.loading === false) {
                    state.loading = true;
                    state.usertags = [];
                }
            })
            .addCase(fetchUserTags.fulfilled, (state, action) => {
                if (state.loading === true) {
                    state.loading = false;
                    state.usertags = action.payload;
                }
            })
            .addCase(fetchUserTags.rejected, (state) => {
                if (state.loading === true) {
                    state.loading = false;
                    state.usertags = [];
                }
            });
    },
});
export const { setUserTags } = userTagsSlice.actions;

export default userTagsSlice.reducer;
