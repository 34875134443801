import { Collection } from '@prisma/client';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ResponseTpes } from '@/pages/api/collection/getAllCollections';
import { getAPIURL } from '@/utils/getAPIURL';

export const fetchCollections = createAsyncThunk(
    'fetchAllCollections',
    async () => {
        try {
            const request = await fetch(
                `${getAPIURL()}/api/collection/getAllCollections`,
                {
                    method: 'GET',
                }
            );
            const response: ResponseTpes = await request.json();
            if (response.status) {
                return response.data;
            } else {
                return [];
            }
        } catch (error) {
            return [];
        }
    }
);

type Collections = {
    collections: Collection[];
    loading: 'idle' | 'pending' | 'finished';
    error: string | null | undefined;
};
// Define the initial state using that type
const initialState: Collections = {
    collections: [],
    loading: 'idle',
    error: null,
};

export const collectionsSlice = createSlice({
    name: 'collections',
    initialState,
    reducers: {
        setCollectionData: (state, action: PayloadAction<Collections>) => {
            state.collections = action.payload.collections;
            state.loading = action.payload.loading;
            state.error = action.payload.error;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCollections.pending, (state) => {
                if (state.loading === 'idle') {
                    state.loading = 'pending';
                    state.collections = [];
                }
            })
            .addCase(fetchCollections.fulfilled, (state, action) => {
                if (state.loading === 'pending') {
                    state.loading = 'idle';
                    state.collections = action.payload;
                }
            })
            .addCase(fetchCollections.rejected, (state) => {
                if (state.loading === 'pending') {
                    state.loading = 'idle';
                    state.collections = [];
                }
            });
    },
});
export const { setCollectionData } = collectionsSlice.actions;

export default collectionsSlice.reducer;
