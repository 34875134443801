import { Collection } from '@prisma/client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';

export type Newprops = Pick<
    Collection,
    | 'collection_id'
    | 'sticker'
    | 'collectionName'
    | 'themeColor'
    | 'description'
>;
// Define the initial state using that type
export const initialStateCollection: Newprops = {
    collection_id: '',
    collectionName: '',
    sticker: '',
    themeColor: '#fff',
    description: '',
};

export const collectionSlice = createSlice({
    name: 'collection',
    initialState: initialStateCollection,
    reducers: {
        setThemeColor: (
            state,
            action: PayloadAction<Pick<Newprops, 'themeColor'>>
        ) => {
            state.themeColor = action.payload.themeColor;
        },
        setCollection: (state, action: PayloadAction<Newprops>) => {
            state.collection_id = action.payload.collection_id;
            state.collectionName = action.payload.collectionName;
            state.sticker = action.payload.sticker;
            state.themeColor = action.payload.themeColor;
            state.description = action.payload.description;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setCollection, setThemeColor } = collectionSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getUser = (state: RootState) => state.user;

export default collectionSlice.reducer;
